import { useState } from "react";

function Chairman() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Chairman
                    <br />
                    <span className="text-primary">Swami Divya Dev Ji</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mt-5">
              <div className="row g-3 mt-4 mt-lg-0">
                <div className="col-lg-6 col-12">
                  <img
                    src="/assets/images/chairman.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="400"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <img
                    src="/assets/images/chairman/2.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="400"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <img
                    src="/assets/images/chairman/1.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="400"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container pb-6">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="">
                <h1 className="display-4 fw-bold text-center">
                  Swami <span className="colorful-text"> Divya Dev Ji</span>
                </h1>
              </div>
              <p className="lead text-center mt-5 p-5 bg-light rounded-5">
                परम पूज्य गुरुदेव और श्रद्धेय आचार्य श्री जी की संकल्पना,
                दूरदृष्टि, तप, पुरुषार्थ का परिणाम और आधुनिक समय की आवश्यकता है
                “आचार्यकुलम्‌"। आचार्यकुलम् विद्यालय आधुनिक और वैदिक शिक्षा का
                केन्द्र होने के साथ ही समाज के लिए प्रज्ञावान, संस्कारवान और
                चरित्रवान मानव निर्माण करने वाला संस्थान है| आचार्यकुलम्‌
                विद्यालय निरंतर ऋषि-मुनियों और वेदों की परंपरा को आगे बढ़ाने
                वाला प्रतिष्ठान है| विद्यालय परिसर का सौम्य, सात्विक, दिव्य
                वातावरण और विभिन्‍न प्रकार के फलदार पेड़-पौधे, रंग-बिरंगे पुष्प
                तथा पशु-पक्षी बच्चों को प्रकृति के निकट लाते हैं। तथा विद्यालय
                परिसर को और भी अधिक भव्यता और दिव्यता प्रदान करते हैं| विद्यालय
                परिसर में विद्यार्थियों के लिए आवश्यक सभी शिक्षण सामग्री, खेल
                उपकरण, स्मार्ट बोर्ड, कम्प्यूटर लैब, लाइब्रेरी, विज्ञान तथा
                भौतिकी लैब के साथ ही योग्य तथा अनुभवी अध्यापक गणों की उपस्थिति
                है| विद्यालय परिवार में विद्यार्थी, अध्यापक, अभिभावक तथा सेवा
                सहयोगी सभी लोग मिलकर हमारे मूल उद्देश्य “देश के लिए श्रेष्ठ
                नेतृत्व कर्ता तैयार करना” को पूरा करने के लिए अहर्निस संकल्पित
                है|
              </p>

              {isOpen && (
                <div>
                  <p className="lead text-center p-5 bg-light rounded-5">
                    विद्यालय की सभी गतिविधियों को संक्षिप्त रुप में इस पत्रिका
                    के माध्यम से दर्शाया जा रहा है| इस पत्रिका में सभी बच्चों और
                    अध्यापकों के मन की अभिव्यक्ति है । जब हम इस पत्रिका को
                    अध्ययन करेंगे तब इसमें बच्चों तथा अध्यापकों की मनः स्थिति
                    उनकी उपलब्धि और संस्था की प्रगति का दिग्दर्शन करेंगे ।
                    'अभिव्यक्ति” पत्रिका का उद्देश्य विद्यालय की गतिविधियों से
                    परिचय कराना तथा संस्था की उपलब्धि, विद्यार्थियों की उपलब्धि
                    और उनकी चेतना का स्तर, चारित्रिक निर्माण, उनके संस्कार और
                    शैक्षणिक कार्यक्रमों के बारे में जानकारी प्रदान करना है |
                    शिक्षा समाज में एक महत्वपूर्ण भूमिका निभाती है, जो हमारी
                    बुद्धि में ज्ञान का संचार करती है, हमारी सोच तथा व्यवहार को
                    प्रभावित करती है । यह हमें नई विचारधारा, नैतिकता, आदर्शों और
                    समृद्धि की ओर ले जाती है। संस्कार हमारे सामाजिक और व्यक्तिगत
                    जीवन में नैतिक मूल्यों और आदर्शों का प्रतिनिधित्व करते हैं
                    जो हमें शुभ आचरण तथा उच्च आदर्शों की प्राप्ति की ओर अग्रसर
                    करते हैं ।
                    <br />
                    <br /> अभ्युदय एक सकारात्मक विकास की प्रक्रिया है जो समाज
                    में ज्ञान, विद्या और कौशल के माध्यम से होती है | इसके द्वारा
                    व्यक्ति की समृद्धि और समाज का विकास होता है| निश्रेयस: एक
                    उच्च चेतना की स्थिति है जो शिव संकल्प, सत्कर्मों और सच्चे
                    प्रयासों के माध्यम से प्राप्त होती है | यह हमें समाज में
                    सकारात्मकता तथा आध्यात्मिक उन्नति की दिशा में ले जाता है। जो
                    अंत में आध्यात्मिक और भागवत प्रेरणा की प्राप्ति हेतु मदद
                    करता है | हम शिक्षा के माध्यम से एक स्वस्थ, समृद्ध और
                    संस्कारवान समाज की स्थापना करने में सफल हो ऐसी परमपिता
                    परमात्मा से प्रार्थना है | आचार्यकुलम्‌ राँची राष्ट्र और
                    विश्‍व में सकारात्मक परिवर्तन लाने के लिए उच्च गुणवत्ता की
                    शिक्षा प्रदान कर रहा है | हमारा प्रयास है कि यहाँ के
                    विद्यार्थी न केवल विज्ञान और तकनीकी के क्षेत्र में उत्कृष्ट
                    हों, बल्कि उन्हें धार्मिकता, नैतिकता और सामाजिक संवेदनशीलता
                    के महत्व को भी समझाया जाए | आशा है कि हमारे इस अभियान में आप
                    सभी शिक्षकों, विद्यार्थियों एवं अभिभावकगणों का समर्थन एवं
                    सहयोग निरंतर प्राप्त होगा जिससे कि हम अपने समाज और राष्ट्र
                    में सकारात्मक प्रभाव डाल सकें |
                    <br />
                    <br />
                    आप सभी शिक्षकों, विद्यार्थियों एवं अभिभावकगणों के लिए मेरी
                    ओर से अनन्त शुभकामनाएँ और ईश्वर से प्रार्थना है कि आप सभी के
                    जीवन में समृद्धि और स्वास्थ्य बढ़ता रहे।
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-center">
                {!isOpen && (
                  <button
                    onClick={toggleContent}
                    className="btn-outline-dark-secondary mt-4 px-5 py-2 bg-transparent border-1 border-secondary rounded-4"
                  >
                    <span className="mx-3 fs-4">Read More</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chairman;
