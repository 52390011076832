import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Introduction from "./pages/Introduction";
import MissionVision from "./pages/MissionVision";
import SwamiRamdevJi from "./pages/SwamiRamdevJi";
import AcharyaBalkrishnaJi from "./pages/AcharyaBalkrishnaJi";
import Principal from "./pages/Principal";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import MandatoryDisclosure from "./pages/MandatoryDisclosure";
import AdmissionProcedure from "./pages/AdmissionProcedure";
import StudentCouncil from "./pages/StudentCouncil";
import Facility from "./pages/Facility";
import CourseCurriculum from "./pages/CourseCurriculum";
import ExaminationPolicy from "./pages/ExaminationPolicy";
import AttendanceRegularity from "./pages/AttendanceRegularity";
import HouseSystem from "./pages/HouseSystem";
import Chairman from "./pages/Chairman";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="introduction" element={<Introduction />} />
        <Route path="mission-vision" element={<MissionVision />} />
        <Route path="swami-ramdev-ji" element={<SwamiRamdevJi />} />
        <Route path="acharya-balkrishna-ji" element={<AcharyaBalkrishnaJi />} />
        <Route path="chairman" element={<Chairman />} />
        <Route path="principal" element={<Principal />} />
        <Route path="career" element={<Career />} />
        <Route path="contact" element={<Contact />} />
        <Route path="mandatory-disclosure" element={<MandatoryDisclosure />} />
        <Route path="admission-procedure" element={<AdmissionProcedure />} />
        <Route path="attendance-regularity" element={<AttendanceRegularity />} />
        <Route path="house-system" element={<HouseSystem />} />
        <Route path="student-council" element={<StudentCouncil />} />
        <Route path="facility" element={<Facility />} />
        <Route path="course-curriculum" element={<CourseCurriculum />} />
        <Route path="examination-policy" element={<ExaminationPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
