export const facilities = [
  {
    name: "Natural Environment with Lush Green Campus",
    content:
      "The  Acharyakulam is established in a green and spacious campus that transmits the children to a totally different environment from the hustle and bustle of the city to a calm, spiritual and serene ambience. The campus has a stretch of green space all around the school building creating an apt environment for the students to concentrate and focus in their academics. Besides the infrastructures are regularly upgraded to meet the upcoming requirements of the school. The school has lush green  campus  with  varieties  of decorative  plants  and  medicinal  herbs.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },
  {
    name: "Administrative Block",
    content:
      "The  nodal  point  of  our  campus,  the  Acharyakulam Administrative  Block  is  designed  to reflect  the  lively  and Vedic  transparent  character  of  the  school.  It  accommodates key staff offices  and departments, a reception desk, a conference centre, a visitor’s lounge as well as an outlet for complete school campus.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Assembly Area",
    content:
      "The school has  two  big assembly  areas  where the students start their day with yagya, hawana and yoga to refresh and energise themselves for the day in a spiritual note.  One is  a  Green court  in the ground floor  and  other a hall  on the fourth-floor.  Both the space is even the epicentre of various   school cultural activities.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Game Facilities",
    content:
      "There are few things as joyful as to behold children at play. Also, a child's body needs to be exercised as much as the mind. Thus, Acharyakulam can boast of its sports facilities which includes 3 huge playgrounds which are fit to host all national tournaments. A special sports room for Indoor games has been especially designed to play table tennis,  carom, chess, martial arts  etc.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Spacious Playground",
    content:
      "The school has three huge playgrounds with ample space. A separate area is dedicated to equipments  to enhance the motor skills of pre primary students like slides, swings, climbers, spring riders, spinners and seesaw for children. Outdoor games like cricket,  football, volleyball, hockey, etc. Can be played in this spacious playground.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Art & Craft Atelier",
    content:
      "Aakaar - translates to Form and Anant-meaning  amanifestation  of  all  possibilities which are 'Infinite'  inexperience is the venue for learning which is expressive and  intuitive. The school has  Art and Craft room where the young hands are trained and nurtured with utmost care by the qualified faculty. All forms of art like 2-diimensional, 3-Dimensional art, madhubani painting , silhouette painting , etc are taught keeping in mind the interest of the students.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Yog",
    content:
      "Yoga is the fundamental element of Patanjali Yogpeeth, the institute through which the well-being of the entire human race can be achieved. Yoga plays a vital role in our lives, which is why special emphasis is given to yoga in Acharyakulam School. Yoga classes are conducted daily in the school. From the early classes, students are provided with yoga education. Regular practice of yoga keeps the body and mind healthy as it is through a healthy body and mind that children's intellectual development takes place.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Yagya",
    content:
      "Keeping in with the VEDIC tradition students participate in Yagya and Havan done every morning to enable them to be deeply rooted to their culture. It is a ritual worship making offerings to the devas through the sacred fire (Agni). The students recite mantras and follow the procedure of havan.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Vedic Center",
    content:
      "Last but not the least to mention is our Vedic room where the students get the opportunity to be close to our Vedic culture with a special team of our Sanskrit teachers who are trained in Haridwar to teach our students  The Gita Shlokas with the proper pronunciation and diction.",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Library",
    content:
      "The digital age cannot lessen the importance of school libraries. In the context of schools, libraries play a significant role in providing information to students in the academic field. The books stored on the shelves of libraries are the source of wisdom and knowledge. The school has well equipped library for both Senior and Primary wing. The books on various topics, story books, novels, motivational books, poems, cartoon books etc. are arranged sequentially thereby catering the needs of individuals. ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Dance Studio",
    content:
      "A big spacious room is alloted for promoting the art of dance among the students, headed by a very well experienced dance guru who excels in both classical and western dance form.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Music Lab",
    content:
      "A separate space has been dedicated to music which is the heart and soul of our Indian culture. Vocal and Instrumental  both the art is promoted by our highly learned, experienced and talented music teachers.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Health & Care Unit",
    content:
      "Medical camps are organized, and check-ups done for oral, dental, Ophthalmology and general  health of children on an  ongoing  basis. The school has 2 sick rooms separately for boys and girls with all medical facilities. A qualified and trained nurse is available 24x7 to attend an kind of casualties or emergencies.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Transport Facility",
    content:
      "The Acharyakulam operates a fleet of buses that ply on pre-determined routes throughout Ranchi. Our buses are regularly inspected by Quality Controllers and each bus carries a staff member with an on- board Phone, and every bus have been equipped with GPS tracking system for the ease of parents.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Computer Lab",
    content:
      "Technology plays a vital role in modern education, which is fulfilled by our fully equipped computer-lab with 50 computers, having access to wi-fi enabling a class to sit comfortably and practice lab assignments with 1: 1 ratio under the guidance of our qualified faculty.  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },

  {
    name: "Kids Activity Zone",
    content:
      "The school has two activity rooms for small  kids to enable them to enhance in all co- curricular activities  like  Poem recitation, debate, speech, declamation, essay writing painting, sketching, calligraphy, poster making, collage making, mock parliament etc.	  ",
    images: ["/assets/images/photos/3.jpg", "/assets/images/photos/2.jpg"],
  },
];
