function MandatoryDisclosure() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Mandatory Disclosure
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-6 mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-12">
              <div class="text-center">
                <img src="/assets/images/icon.png" width="120" />
                <h1 class="display-5 fw-bold mt-4">
                  ACHARYAKULAM
                  <br />
                  TATA ROAD NAMKUM RANCHI
                </h1>
                <p class="lead px-8">
                  Integrating Ancient Gurukul Wisdom with Modern Educational
                  Excellence
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pb-8 mb-4">
        <div class="container">
          <div class="row g-0">
            <div class="col-lg-7 col-12">
              <div className="bg-light p-lg-7 p-5 rounded-lg-5 rounded-5 h-100">
                <div class="py-3 mb-5 border-bottom">
                  <h3>BASIC DETAILS</h3>
                </div>
                <div class="mt-5">
                  <p class="fs-4 mb-1">
                    School Name :
                    <span className="text-dark"> Acharyakulam</span>
                  </p>
                  <p class="fs-4 mb-1">
                    Address :
                    <span className="text-dark">
                      {" "}
                      Tata Road, Near Namkum Police Station, Ranchi - JH
                    </span>
                  </p>
                  <p class="fs-4 mb-1">
                    School's Email ID :
                    <span className="text-dark">
                      {" "}
                      officeacharyakulamranchi@gmail.com
                    </span>
                  </p>
                  <p class="fs-4 mb-1">
                    School Contact No :
                    <span className="text-dark"> 6287842461, 6287842463</span>
                  </p>
                  <p class="fs-4 mb-1">
                    School Website :
                    <span className="text-dark">
                      {" "}
                      www.acharyakulamranchi.com
                    </span>
                  </p>
                  <p class="fs-4 mb-1">
                    Landmark :
                    <span className="text-dark">
                      {" "}
                      Near Namkum Police Station, Tata Road, Ranchi
                    </span>
                  </p>
                  <p class="fs-4 mb-1">
                    Name of the Trust/Society/Company Registers with :
                    <span className="text-dark"> Patanjali Yogpeeth Trust</span>
                  </p>
                  <p class="fs-4 mb-1">
                    Whether School is Running :
                    <span className="text-dark"> Morning</span>
                  </p>
                  <p class="fs-4 mb-1">
                    School Timing :
                    <span className="text-dark"> 7:20 AM to 1:30 PM</span>
                  </p>
                  <p class="fs-4 mb-3">
                    Currently Running Classes from :
                    <span className="text-dark"> Pratyush (Nursery)</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-12 mt-lg-0 mt-5">
              <img
                src="/assets/images/photos/2.jpg"
                className="w-100 h-100 rounded-lg-5 rounded-5 object-fit-cover"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MandatoryDisclosure;
