function Contact() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Contact Us
                    <br />
                    <span className="text-primary">Get in Touch</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="row align-items-center min-vh-100">
          <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-12 col-12">
            <div className="py-8 me-xl-8 pe-xl-8">
              <div>
                <h1 className="display-4 fw-bold">Get In Touch.</h1>
                <p className="lead text-dark">
                  Fill in the form to the right to get in touch with someone on
                  our team, and we will reach out shortly.
                </p>
                <div className="mt-8 fs-4">
                  <p className="mb-4">
                    If you need assistance, please reach out to us directly via
                    email or phone call.
                  </p>
                  <p>
                    <i className="bi bi-telephone text-primary me-2" />+ 91
                    6287842467
                  </p>
                  <p>
                    <i className="bi bi-envelope-open text-primary me-2" />
                    info@acharyakulamranchi.com
                  </p>
                  <p className="d-flex">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    Namkum, Near Police Station, Ranchi, India, Jharkhand
                  </p>
                </div>
                <div className="mt-8">
                  <a href="#" className="me-3">
                    <i className="bi bi-facebook fs-3"></i>
                  </a>
                  <a href="#" className="me-3">
                    <i className="bi bi-twitter-x fs-3"></i>
                  </a>
                  <a href="#" className="me-3">
                    <i className="bi bi-instagram fs-3"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-youtube fs-3"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-lg-flex align-items-center w-lg-50 min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0 right-0">
            <div className="px-4 px-xl-8 mx-xl-8 py-8 py-lg-0">
              <div>
                <form className="row needs-validation" noValidate="">
                  <div className="mb-3 col-12 col-md-12">
                    <label className="form-label" htmlFor="contactFistName">
                      Full Name
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control py-3 rounded-4"
                      type="text"
                      name="last name"
                      id="contactFistName"
                      placeholder="Full Name"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter full name
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="contactEmail">
                      Email
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control py-3 rounded-4"
                      type="email"
                      id="contactEmail"
                      name="contactEmail"
                      placeholder="Email"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter an email
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="contactPhone">
                      Phone No
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control py-3 rounded-4"
                      type="text"
                      id="contactPhone"
                      name="contactPhone"
                      placeholder="Phone"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter Phone Number
                    </div>
                  </div>
                  <div className="mb-3 col-12">
                    <label
                      className="text-dark form-label"
                      htmlFor="contactReason"
                    >
                      Contact Reason
                      <span className="text-danger"> *</span>
                    </label>
                    <select
                      className="form-select py-3 rounded-4"
                      data-choices=""
                      id="contactReason"
                      required=""
                    >
                      <option value="">Select</option>
                      <option>Admission</option>
                      <option>TC</option>
                      <option>Complaint</option>
                      <option>Other</option>
                    </select>
                    <div className="invalid-feedback">
                      Please choose contact reason
                    </div>
                  </div>
                  <div className="mb-3 col-12">
                    <label
                      className="text-dark form-label"
                      htmlFor="contactMessages"
                    >
                      Message
                    </label>
                    <textarea
                      className="form-control py-3 rounded-4"
                      id="contactMessages"
                      rows={3}
                      placeholder="Messages"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary px-5">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
