function Principal() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Principal
                    <br />
                    <span className="text-primary">Mrs. Sujata Kaura</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="row align-items-center py-lg-3">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  OUR PRINCIPAL
                </span>
                <h2 className="display-5 mt-2 mb-3 fw-bold">
                  Mrs. Sujata Kaura
                </h2>
                <h3>
                  Our Principal is a dedicated leader committed to fostering a
                  positive and inspiring school environment.
                </h3>
                <div className="mt-6">
                  <p className="mb-0 fs-4">
                    As we embark on another chapter of our educational odyssey,
                    I am reminded of the profound words of Nelson Mandela:
                    "Education is the most powerful weapon which you can use to
                    change the world". Here at our esteemed institution, we are
                    dedicated to wielding this weapon judiciously and seamlessly
                    interwining ancient wisdom with contemporary educational
                    methodologies to empower burgeoning minds and sculpt the
                    leaders of tomorrow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src="/assets/images/principal.jpg"
                  alt="featured"
                  className="w-100 object-fit-cover rounded-5"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container mb-3">
          <div className="p-lg-7 p-5 bg-light rounded-5">
            <p className="fs-4">
              In the timeless words of Confucius: "Education breeds confidence.
              Confidence breeds hope. Hope breeds peace". Our commitment to
              excellence in education not only transcends mere academic
              instruction; but it also encompasses nurturing confidence,
              kindling hope, fostering serenity, and enriching the soul with
              spiritual and moral values, fostering a holistic educational
              experience with our cherished school community.
              <br />
              <br />
              We firmly believe the tapestry of education extends far beyond the
              confines of the classroom; it thrives within the intricate web of
              relationships we weave. Helen Keller once eloquently remarked,
              "Alone, we can do so little; together, we can do so much". The
              profound connections forged among parents, students and educators
              form the bedrock of our collective success. Together, we
              orchestrate a symphony of learning, where every voice resonates,
              and every contribution is cherished.
              <br />
              <br />
              Our educational mission is not solely about preparing students for
              the present reality, but also for a world brimming with
              compassion, innovation, understanding and spiritual enlightenment
              through the amalgamation of yog, darshan and spirituality which
              are interconnected and deep rooted in the profound evolution of
              mankind. The same principles are implemented at our institution in
              its educational process therby helping our pupils to self-discover
              themselves in their personal journey seeking a deeper connection
              with the self, realising their potential, attaining inner harmony
              and spreading the same optimistic aura around them.
              <br />
              <br />
              In today's world, where life is fast-paced and demanding; yog,
              darshan and spirituality offer valuable tools for well-being of an
              individual and the society, which is why we have incorporated them
              in our institution and given them utmost momentous for the benefit
              of our students. Yoga enhances one's focus, reduces stress and
              promotes well being, aiding in overall academic performance of
              students. Darshan, through philosophical insights, encourages
              critical thinking and a broader perspective among young minds.
              Spirituality provides a sense of purpose, resilience and emotional
              balance crucial during the challenges of students' lives
              Integrating these practices contribute to a more holistic and
              fulfilling educational experience and yields a wholesome younger
              generation which ultimately leads to a great and glorified nation.
              <br />
              <br />
              <hr />
              <br />
              In conclusion, I implore each of you to embrace the journey ahead
              with boundless enthusiasm and unyielding determination. Let us
              continue to interlace the wisdom of antiquity with the ingenuity
              of the present and the Spiritual Enlightenment of our cherished
              yog and yagya tradition to illuminate the path towards a luminous
              future for all.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Principal;
