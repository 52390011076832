import { useState } from "react";

function SwamiRamdevJi() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Yogrishi Swami Ramdev Ji
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mt-5">
              <div className="row g-3 mt-4 mt-lg-0">
                <div className="col-lg-4 col-6">
                  <img
                    src="/assets/images/swamiji/1.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover object-position-top rounded-5"
                    height="500"
                  />
                </div>
                <div className="col-lg-8 col-12">
                  <img
                    src="/assets/images/swamiji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-4 fw-bold mb-5">
                  Yogrishi Swami{" "}
                  <span className="colorful-text">Ramdev Ji</span>
                </h1>
                <p className="lead px-8 p-5 bg-light rounded-5">
                  शिक्षा, भाषा, भाषा बोध, विषय बोध या सूचनाओं का संग्रह मात्र
                  नहीं है, अपितु इन सबके प्रमाणिक बोध के साथ स्वयं में सन्निहित
                  अनन्त ज्ञान, संवेदना, असीम सामर्थ्य, शौर्य, वीरता, पराक्रम एवं
                  पुरुषार्थ को जानकर,सही दिशा में लगाकर स्वयं, अपने परिवार,
                  समाज, राष्ट्र एवं विश्व के लिए श्रेष्ठतम योगदान देना है |
                  विद्याभ्यास के साथ-साथ योगाभ्यास एवं श्रेष्ठ व्रतों के अभ्यास
                  से आत्मानुशासन, आत्म-रूपान्तरण, आत्मप्रेरणा, आत्मस्पर्धा,
                  आत्मबोध से आत्माएँ सन्निहित बीजरूप सामर्थ्य को इतना विकसित कर
                  लें कि ब्रह्माण्डीय समस्त ऊर्जाएँ हमारे पिण्ड से अभिव्यक्त
                  होने लगें । विद्याभ्यास, विविध कुशलताओं एवं दैवी संपदाओं से
                  स्वयं के जीवन को सुंदरतम रूप में विकसित करें। आपके शरीरबल,
                  मनोबल, बुद्धिबल एवं आत्मबल को पूर्ण विवेक, पूर्ण श्रद्धा एवं
                  पूर्ण पुरुषार्थ से जागृत करने हेतु श्रेष्ठतम प्रशिक्षण,
                  श्रेष्ठतम प्रशिक्षकों द्वारा प्राप्त हो तथा इस कार्य हेतु
                  श्रेष्ठतम वातावरण उपलब्ध होना चाहिए | विद्यार्थी जीवन की समस्त
                  चुनौतियों, संघर्षों, संकटों से जूझते हुए विकल्प रहित संकल्प,
                  अखण्ड एवं प्रचण्ड पुरुषार्थ से स्वयं को विजयी बनाएँ | बड़ी
                  सोच, कड़ी मेहनत एवं दृढ़ संकल्प के साथ अपने भीतर ऐसा
                  व्यक्तित्व, चरित्र, नेतृत्व एवं व्यक्तित्व विकसित करें कि वे
                  एक श्रेष्ठतम विश्व नागरिक के रूप में स्वयं को गढ़ सकें और अपनी
                  दिव्य मेधा, प्रतिभा, पुरुषार्थ एवं पराक्रम से नया सामाजिक,
                  आर्थिक, राजनैतिक एवं आध्यात्मिक तंत्र स्थापित कर सकें ।
                </p>
                {isOpen && (
                  <div>
                    <p className="lead px-8 p-5 bg-light rounded-5">
                      यदि हमें राष्ट्र को समृद्ध, संस्कारवान बनाना है तो शिक्षा
                      ही एक मात्र माध्यम है | हम विज्ञान के माध्यम से अंतरिक्ष
                      की यात्रा कर रहे हैं तो दूसरी ओर विज्ञान के संसाधनों के
                      माध्यम से ही सांस्कृतिक, चारित्रिक, बौद्धिक, वैचारिक,
                      सामाजिक पतन का भी स्वागत कर रहे हैं | वर्तमान में हम
                      विकासशील राष्ट्र से विकसित राष्ट्र की गौरवशाली यात्रा कर
                      रहे हैं | हम सदैव से संपूर्ण संसार का मार्गदर्शन करने
                      वालें राष्ट्र के रूप में जाने जाते रहे हैं । हमें पुन:
                      खोया हुआ वैभव, गौरव प्राप्त करना है, राष्ट्र को विश्व की
                      महाशक्ति के रूप में स्थापित करना है जो केवल शिक्षा के
                      माध्यम से ही संभव हो सकता है | प्राचीन काल से हम अपनी गुरु
                      परंपरा के माध्यम से अपने ज्ञान को प्रकाशित करते हुए आगे
                      बढ़ रहे हैं, समय-समय पर विभिन्‍न महापुरुषों ने अपने
                      सर्वस्व का न्यौछावर कर इस भारत भूमि को अपनी तपस्या और
                      ज्ञान से सिंचित किया है, भारतीय शिक्षा बोर्ड के माध्यम से
                      हम अपने गौरवशाली अतीत को पुनः स्थापित करेंगे और हम अपने
                      देश को विश्व की महाशक्ति के रूप में स्थापित करेंगे तथा
                      पुनः यह राष्ट्र विश्व गुरु के पद पर आसीन होगा, इन्हीं
                      संकल्पों को साकार करते हुए हमारे पतंजलि योगपीठ के सभी
                      शिक्षण संस्थान अपना संपूर्ण पुरुषार्थ कर रहे हैं | <br />
                      <br /> निश्चित ही हम शिक्षा माध्यम से एक स्वस्थ, समृद्ध और
                      संस्कारवान समाज की स्थापना करने में सफल होंगे ऐसी परमपिता
                      परमात्मा से प्रार्थना है । हम आश्वस्त हैं कि सभी
                      विद्यार्थी, गुरुजन, आचार्य, प्राचार्य, अभिभावक एवं विद्या
                      क्षेत्र में संलग्न सभी पुण्यात्माएँ विद्या कार्य को
                      सर्वोपरि गौरव प्रदान करते हुए, ब्रह्माण्ड के इस श्रेष्ठतम
                      कार्य को एक अनुष्ठान की तरह निष्पादित करेंगी | शिक्षा से
                      बड़ा संसार में कोई भी निर्माण कार्य नहीं है । सारे संसार
                      को यदि सभी दिशाओं से श्रेष्ठतम रूप में विकसित करना है, तो
                      मूल्यबोध मूलक श्रेष्ठतम वैज्ञानिक शिक्षा-दीक्षा एवं
                      संस्कार ही एकमात्र साधन, साध्य एवं समाधान है। हमारा शेष
                      जीवन विद्या सेवा हेतु पूर्णतः: समर्पित है| भारतीय शिक्षा
                      बोर्ड, पतंजलि गुरुकुलम्‌, आचार्यकुलम्‌ एवं पतंजलि
                      विश्वविद्यालय के राष्ट्र व विश्वव्यापी प्रामाणिक विस्तार
                      हेतु, मैं आप सभी दिव्य आत्माओं को आमन्त्रित करता हूँ।
                      आचार्यकुलम्‌ के द्वारा हम भारत को श्रेष्ठ नागरिक देने के
                      साथ ही सुदृढ़ नेतृत्व प्रदान करने हेतु योग्य विद्यार्थी
                      तैयार कर रहे हैं ताकि वह हमारे पूर्वज ऋषि-मुनियों के आदर्श
                      पर चल कर भारत को पुन: विश्वगुरु बनाएं |
                      <br />
                      <br />
                      मुझे प्रसन्नता है कि आचार्यकुलम्‌ राँची मेरे उद्देश्यों के
                      अनुरूप ही कार्य कर रहा है। विद्यालय के अच्छे विद्यार्थी,
                      अध्यापक तथा सभी सेवाकर्मी परोपकारी भाई-बहनों को मेरा सहृदय
                      शुभाशीष |
                    </p>
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {!isOpen && (
                    <button
                      onClick={toggleContent}
                      className="btn-outline-dark-secondary mt-4 px-5 py-2 bg-transparent border-1 border-secondary rounded-3"
                    >
                      <span className="mx-3 fs-4">Read More</span>
                    </button>
                  )}
                </div>
              </div>
              <p className="lead text-center mt-5"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SwamiRamdevJi;
