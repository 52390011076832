import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { facilities } from "../facalities";

function Facility() {
  const { state } = useLocation();
  const facilityName = state?.facilityName;

  const [facility, setFacility] = useState(null);

  useEffect(() => {
    if (facilityName) {
      const foundFacility = facilities.find(
        (facility) => facility.name === facilityName
      );
      setFacility(foundFacility);
    }
  }, [facilityName]);

  if (!facility) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">{facility.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="row align-items-center py-lg-5">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  OUR FACILITY
                </span>
                <h2 className="display-4 mt-4 mb-4 fw-bold lh-1">
                  {facility.name}
                </h2>
              </div>
            </div>
            <div className="col-12">
              <p className="lead lh-sm">{facility.content}</p>
            </div>
          </div>

          <div className="row g-3 mb-4">
            {facility.images.map((image, index) => (
              <div className="col-lg-4 col-6" key={index}>
                <img
                  src={image}
                  className="w-100 rounded-4 object-fit-cover"
                  height="300"
                  alt="Facility"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Facility;
