import { useState } from "react";

function AcharyaBalkrishnaJi() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Shraddhey Acharya Balkrishna Ji
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mt-5">
              <div className="row g-3 mt-4 mt-lg-0">
                <div className="col-lg-8 col-12">
                  <img
                    src="/assets/images/acharyaji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
                <div className="col-lg-4 col-6">
                  <img
                    src="/assets/images/acharyaji/1.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container pb-6">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-4 fw-bold">
                  Shraddhey Acharya{" "}
                  <span className="colorful-text">Balkrishna Ji</span>
                </h1>
              </div>
              <p className="lead mt-5 p-5 bg-light rounded-5">
                "शिक्षा ” हमारी महान सांस्कृतिक विरासत का प्रमुख अंग है । यह
                शिक्षा भारत के महान ऋषि-मुनियों के द्वारा आशीर्वाद के रूप में
                विश्व को दी गई सर्वोत्तम भेटों में से एक है | जिस प्रकार किसी भी
                इमारत की मजबूती उसकी नींव की श्रेष्ठता पर निर्भर करती है, उसी
                प्रकार विद्या जीवन का आधार है, क्योंकि शरीर, मन एवं आत्मा तीनों
                स्तरों में सामंजस्य स्थापित करने में यह सहयोगी है | विद्यार्थी
                किसी भी राष्ट्र का भविष्य होते हैं | इनका सम्पूर्ण व्यक्तित्व
                अच्छी शिक्षा, उच्च नैतिक मूल्यों तथा स्वस्थ जीवन शैली पर निर्भर
                होता है। भारत के महान ऋषियों द्वारा मार्गदर्शित विद्या में ही
                मनुष्य की अंतर्निहित शक्तियों का जागरण करने एवं उसके सर्वांगीण
                विकास करने की संभावना को बढ़ाने की क्षमता है |
              </p>

              {isOpen && (
                <div>
                  <p className="lead p-5 bg-light rounded-5">
                    भारत में प्राचीन काल से ही अध्ययन-अध्यापन की सुदीर्घ परम्परा
                    रही है। परन्तु अधिसंख्य समाज वैदिक शिक्षा के अध्ययन से वंचित
                    ही है, विशेषकर 'आज का विद्यार्थी ' | शिक्षा के मुख्य ढाँचे
                    में 'वैदिक शिक्षा' को लाने की आवश्यकता देश में शिक्षाविदों
                    एवं विचारकों द्वारा काफी समय से की जा रही थी | यही कारण है
                    कि भारत सरकार की राष्ट्रीय शिक्षा नीति २०२० में आधुनिक के
                    साथ प्राचीन शिक्षा को विशेष महत्व दिया गया है | इसी क्रम में
                    भारतीय शिक्षा बोर्ड का गठन भी भारत सरकार व पतंजलि योगपीठ के
                    संयुक्त तत्वाधान में किया गया है | शिक्षा का लक्ष्य
                    प्राथमिक, माध्यमिक एवं उच्च माध्यमिक स्तर पर बच्चों को
                    प्रयोगात्मक एवं सैद्धांतिक शिक्षण-प्रशिक्षण देना है | भारतीय
                    शिक्षा बोर्ड द्वारा प्रत्येक कक्षा हेतु ऐसी पाठ्यपुस्तकें
                    तैयार की गई है, जिसमें विषयवस्तु का सहज प्रवाह क्रमोत्तर रूप
                    से हुआ है | पाठ्यवस्तु की बोधगम्यता हेतु संबंधित विषय वस्तु
                    का सरल व रोचक माध्यम से बोध कराया गया है |<br />
                    <br />
                    विद्यार्थी जीवन में बच्चों को सभी प्राणायाम-आसनों का अच्छे
                    से अभ्यास करना चाहिए | इसके लिए प्रत्येक प्राणायाम व आसनों
                    को ध्यानपूर्वक सीखना चाहिए | इस तरह का प्रयोग भारत वर्ष में
                    स्कूली शिक्षा के लिए पहली बार किया जा रहा है जो अपने आप में
                    एक अतुलनीय प्रयास है | बच्चों को महापुरुषों के जीवन चरित्र
                    का भी नियमित अध्ययन करते रहना चाहिए | जिससे बच्चों को
                    प्रेरणा मिलती रहे । इसके साथ ही आयुर्वेदीय आहार एवं औषधि
                    सेवन से सम्बंधित जानकारी भी क्रमोत्तर रूप से प्राप्त करते
                    रहें | प्रत्येक विद्यार्थी को शिक्षकों के द्वारा दी जाने
                    वाली शिक्षा को ध्यानपूर्वक पढ़ना चाहिए | मुझे पूरा विश्वास
                    है कि परम पूज्य योगऋषि स्वामी जी महाराज का संकल्प (स्वस्थ,
                    संस्कारवान, शिक्षित भारत) को साकार करने के लिए आचार्यकुलम्‌
                    में आप सभी अध्यापकों, अभिभावकों, कर्मयोगी भाई-बहनों द्वारा
                    मिलकर किया गया यह प्रयास सभी बच्चों, परिवार, समाज, राष्ट्र
                    एवं विश्व के लिए मंगलमय होगा।
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-center">
                {!isOpen && (
                  <button
                    onClick={toggleContent}
                    className="btn-outline-dark-secondary mt-4 px-5 py-2 bg-transparent border-1 border-secondary rounded-4"
                  >
                    <span className="mx-3 fs-4">Read More</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AcharyaBalkrishnaJi;
