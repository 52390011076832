function CourseCurriculum() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Course & Curriculum
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container mt-3">
          <div className="row">
            <div className="col-12">
              <h1 className="display-5 mb-3">Curriculum</h1>
              <p className="fs-4">
                The curriculum offered at Acharyakulam aims to prepare proven
                scholars in Grammar, Philosophy, Upnishad, Veda, Vaidic
                literature, Vaidic Mathematics, Indian proven history and Raj
                Dharma etc. and to prepare graduates with the power to give
                socially, spiritually, economically, scientifically and
                politically ideal leadership.
              </p>
              <p className="fs-4">
                The curriculum covers both the Scholastic and Co scholastic
                aspects of student's growth and development. Scholastic areas
                include subject and language specific areas whereas Co
                scholastic aspects include life skills, visual and performing
                arts, attitudes and values.
              </p>
            </div>
            <div className="col-12 mt-5">
              <h1 className="display-5 mb-3">Courses of Study</h1>
              <p className="fs-4">
                <div>
                  <span className="fw-semibold">Pratyush (Nursery) : </span>
                  Mathematics, Hindi, Mathematics, Art
                </div>
                <div>
                  <span className="fw-semibold">Arun (LKG) : </span>
                  English, Hindi, Mathematics, GK, Art, Sanskrit
                </div>
                <div>
                  <span className="fw-semibold">Uday (UKG) : </span>
                  English, Hindi, Mathematics, GK, Art, Sanskrit
                </div>
                <div>
                  <span className="fw-semibold">I to V : </span>
                  English, Hindi, Mathematics, EVS, Computer (AI), GK, Sanskrit,
                  Art, Music
                </div>
                <div>
                  <span className="fw-semibold">VI to VIII : </span>
                  English, Hindi, Mathematics, Science, Social Science, Computer
                  Science (AI), GK, Art Education, Music, Sanskrit
                </div>
                <div>
                  <span className="fw-semibold">IX & X : </span>
                  English, Hindi, Mathematics, Science, Social Science,
                  Artificial Intelligence, Information Technology, Art
                  Education, Work Education
                </div>
              </p>
            </div>
            <div className="col-12 mt-4">
              <h1 className="display-5 mb-3">Co-Scholastic Courses</h1>
              <ul>
                <li>Music</li>
                <li>Art</li>
                <li>Geeta</li>
                <li>Yoga</li>
                <li>Health & Physical Education</li>
                <li>Work Education</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CourseCurriculum;
