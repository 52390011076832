function MissionVision() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Mission & Vision
                    <br />
                    <span className="text-primary">Intoduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pt-8">
        <div class="container mt-5">
          <div className="bg-light rounded-5 py-lg-5 py-10 mb-5 d-flex justify-content-center text-center">
            <div class="col-md-11 col-12 mt-5">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2493/2493945.png"
                width="60"
              />
              <h2 class="display-5 my-3">Our Vision</h2>
              <p class="lead px-lg-8 mb-6">
                आचार्यकुलम् परिवार के सदस्यों से अपेक्षित आचरण
                <br />
                <br />
                ज्ञान पक्ष - बाल्यकाल से ही बच्चों का बौध्दिक विकास हमारा मूल
                लक्ष्य है।
                <br />
                <br />
                आचरण पक्ष - स्थूल दोषों से विद्यार्थी 100 प्रतिशत मुक्त रहें तथा
                सूक्ष्म दोषों को अनुभव करके पूरी ईमानदारी से उनको दूर करने के
                लिए संकल्पित रहें जिससे कि हम गर्व से कह सकें कि हमारे पूर्वज
                कैसे थे? आचार्यकुलम्‌ के विद्यार्थी व स्नातकों जैसे थे। एक आदर्श
                ईश्वर पुत्र, ऋषि-ऋषिकाओं व वीर-वीरांगनाओं की सन्तान तथा भारत
                माता या धरती माता की श्रेष्ठ सन्तानों को जैसा आचरण रखना चाहिए
                वैसा ही आचरण हमारे आचार्यों, विद्यार्थियों व स्नातकों का हो।
                अशुभ आचरण के प्रति प्रतिपक्ष भावना अत्यन्त सबल होनी चाहिए। जैसी
                हमारी भावना माँसाहार, दुराचार, हिंसा व दुर्व्यसनों के प्रति होती
                है, वैसी ही भावना जीवन के सूक्ष्म दोषों - वासना, निराशा, आवेग,
                आग्रह एवं प्रतिक्रिया आदि के प्रति निर्मित हो।
                <br />
                <br />
                अशुभ का हम स्वागत न करें। यह सत्य है कि अनादि काल से हमारे साथ
                जुड़ा कर्माशय, संस्कार, इस जन्म का स्वभाव व प्रवृति दोष हमारे
                सामने सबसे बड़ी चुनौती या बाधा बनकर बीच में आयेगा लेकिन दृढ़
                संकल्प व उत्साह के साथ विवेक पूर्वक योग, साधना व तप: पूर्ण
                वातावरण में गुरुजनों के पावन सान्निध्य, आशीर्वाद, आज्ञानुवर्तन,
                प्रियाचरण एवं प्रभुकृपा से हम इस समस्या पर भी विजय पाकर निश्चय
                ही अपने समस्त अशुभ संस्कारों को दग्धबीज या निर्बीज करेंगे । इस
                पवित्र संकल्प को हमें अपने जीवन में प्रतिपल क्रियान्वित करना है।
                <br />
                <br />
                अभिभावकों से अपेक्षित आचरण
                <br />
                <br />
                अभिभावक स्थूल दोष से ।00 प्रतिशत मुक्त होने चाहिए। ऋषि-ऋषिकाओं,
                वीर-वीरांगनाओं एवं महापुरुषों के पथानुगामी रहें। स्वधर्म
                (वेदधर्म, मातृधर्म, पितृधर्म, राष्ट्रधर्म) के प्रति पूर्ण
                निष्ठावान्‌ होने चाहिए।बालक-बालिकाओं के प्रति पूर्ण स्नेह के
                साथ-साथ उनके उत्कर्ष हेतु अभिभावकों में उदारता एवं कठोरता होनी
                चाहिए | संस्थान के प्रति पूर्ण समर्पित, ईमानदार एवं
                निष्ठावान्‌ रहें।
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="pt-8">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 offset-xxl-1 col-xxl-4 col-xl-5 offset-xl-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      1
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">
                        Training Vaidic Scholars for Leadership
                      </h3>
                      <p className="mb-0 fs-3">
                        To develop Vaidic scholars proficient in Grammar,
                        Philosophy, Vedas, Vaidic literature, Mathematics,
                        Indian History, and Raj Dharma, and to equip them for
                        leadership in social, spiritual, economic, scientific,
                        and political realms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6 col-xl-6 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/mv/2.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 order-md-1 order-2 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images/mv/6.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xxl-4 offset-xxl-1 col-xl-5 offset-xl-1 order-xl-2 order-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      2
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">
                        Shaping Leaders for Diverse Fields
                      </h3>
                      <p className="mb-0 fs-3">
                        Preparing leaders of modern Bharata to excel in diverse
                        fields such as Technology, Management, Defence, and
                        more. Our goal is to cultivate intellectuals who set new
                        benchmarks across various disciplines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 offset-xxl-1 col-xxl-4 col-xl-5 offset-xl-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      3
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">Knowledge Aspect</h3>
                      <p className="mb-0 fs-3">
                        To enhance intellectual development of a child is our
                        primary goal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6 col-xl-6 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images//mv/4.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-8 align-items-center gy-4">
                <div className="col-md-6 col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 order-md-1 order-2 mb-xxl-6">
                  <div>
                    <img
                      src="/assets/images//mv/1.jpg"
                      alt=""
                      className="rounded-5 w-100 object-fit-cover object-position-left"
                      height="400"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xxl-4 offset-xxl-1 col-xl-5 offset-xl-1 order-xl-2 order-1 mb-xxl-6">
                  <div className="d-flex flex-column gap-4">
                    <div className="border border-2 border-primary rounded-circle icon-shape icon-xl fs-3 text-primary">
                      4
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h3 className="mb-0 h2">Behaviour Aspect</h3>
                      <p className="mb-0 fs-3">
                        At Acharyakulam, we aim to eliminate both prominent and
                        latent vices in children. By fostering high moral
                        standards and using practices like yoga and devotion, we
                        strive to uphold integrity and overcome unethical
                        tendencies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pb-8">
        <div class="container mb-5">
          <div className="bg-light rounded-5 py-lg-5 py-10 mb-5 d-flex justify-content-center text-center">
            <div class="col-md-11 col-12 mt-5">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3721/3721184.png"
                width="60"
              />
              <h2 class="display-5 my-3">Our Mission</h2>
              <p class="lead px-lg-8 mb-6">
                पूर्ण जागृत व पूर्ण समर्थ आत्माएँ तैयार करना और इनके द्वारा फिर
                पूर्ण जागृत प्रकाशित, विकसित तथा शारीरिक, मानसिक, बौद्धिक,
                आध्यात्मिक, सामाजिक, आर्थिक, राजनैतिक व वैज्ञानिक रूप से समर्थ
                परिवार, समाज;राष्ट्र व विश्व का निर्माण करना |
                <br />
                <br />
                आचार्यकुलम्‌ की उपादेयता
                <br />
                <br />
                वैदिक विद्वान्‌ निर्माण योजना- योग, दर्शन, उपनिषद्‌, वेद ,वैदिक
                साहित्य, वैदिक गणित,भारतीय सिद्धान्त, व्याकरण, इतिहास और राजधर्म
                आदि विषयों में सिद्ध विद्वानों का निर्माण करना और मानसिक,
                आध्यात्मिक, आर्थिक, वैज्ञानिक और राजनैतिक रूप से एक आदर्श
                नेतृत्व देने की क्षमता रखने वाले स्नातक विद्यार्थी तैयार करना ।
                <br />
                <br />
                आधुनिक भारत का नेतृत्व - आधुनिक भारत के निर्माण के लिए सेवा के
                विभिन्‍न क्षेत्रों जैसे प्रौद्योगिकी , प्रबन्धन, रक्षा सेवा,
                प्रशासनिक सेवा,पुलिस सेवा, चिकित्सा, इंजीनियरिंग, खेलकूद, कला,
                संगीत, नाट्य, कृषि, शिल्प व शोध से लेकर उद्योग आदि विविध
                क्षेत्रों में नये कीर्तिमान स्थापित करने हेतु बौद्धिक व
                राष्ट्रीय नेतृत्व तैयार हो । दोनों अपेक्षाओं की महिमा, उपयोगिता
                एवं आकर्षण हम बच्चों में सम्प्रेषित करेगें फिर जैसे - जिसके
                कर्माशय , संस्कार एवं स्वभाव होंगे वैसे वे तैयार होंगे ।पूज्य
                श्री स्वामी जी महाराज का मानना है कि बच्चों में आयु के अनुसार
                प्रतिवर्ष एक-एक प्रतिशत सुधार की सम्भावना क्षीण होती जाती है
                <br />
                <br />
                तथा सामान्य रूप से हर श्रेष्ठ से एक प्रतिशत जागृत होती है |
                ज्ञान युक्त पुरुषार्थ से इस जागृति को हम 'एक (1) प्रतिशत से दस
                (10) प्रतिशत शारीरिक, बौद्धिक व आध्यात्मिक रूप से विकसित कर सकते
                हैं ।
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionVision;
