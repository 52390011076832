function HouseSystem() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    House System
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 mb-4 bg-white">
        <div className="container mt-3">
          <div className="row g-3">
            <div className="col-12">
              <p className="fs-4">
                To inspire a healthy sense of competition, students of
                Acharyakulam have been allocated one of the four houses which
                symbolize the school’s values. Students develop a sense of
                belonging and a feeling of unity in being a part of a specific
                house. The four houses are named Apas or Blue House, Prithvi or
                Green House Tejas or Orange House and Vayu or Yellow House.
              </p>
              <p className="fs-4">
                Houses provide students and faculty with opportunities to
                cultivate rapport beyond the academic environment through
                competitions and events which promote a healthy sense of school
                spirit. Leadership roles and service projects within each House
                offer students of every background opportunities for developing
                the interpersonal skills needed in school life and beyond.
              </p>
              <p className="fs-4">
                Each house is led by a House Master / Mistress for a year. The
                Captain and Vice-captain guide their teams on all fronts and
                play a vital role in leading their team to victory in all
                events. Participating in various inter-house competitions not
                only gives the students a chance to display their talent but
                also helps them learn valuable lessons on team work and selfless
                service. Be it a debate, quiz or sports event, students give
                their best to win laurels and house points. Participation in
                various activities brings the house honour and gives the
                students a sense of pride.
              </p>
            </div>

            <div className="col-12 mt-5">
              <div className="p-lg-6 p-4 rounded-5 bg-light">
                <h1 className="display-5 mb-3 text-info">Apas</h1>
                <p className="fs-4">
                  Apas is one of the five important tattvas, which, according to
                  Hindu philosophy, is Truth or Reality. A Sanskrit word, apas
                  means "water." Earth, water, fire, air and ether are the five
                  important tattvas out of which the water element refers to the
                  apas tattva. Apas means togetherness, reciprocity,
                  brotherhood, fraternity, kinship etc. The students of Apas
                  House are known for their highly intellectual skills,
                  stability and unity.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="p-lg-6 p-4 rounded-5 bg-light">
                <h1 className="display-5 mb-3 text-danger">Tejas</h1>
                <p className="fs-4">
                  Tejas means strength, courage, valour, brilliance, splendour.
                  It also means 'sharp' or 'the point or ‘top of a flame'. Tejas
                  House is also known as Orange House which is the symbol of
                  love, courage, sacrifice, victory and honour. Students of
                  Tejas House stand strong and united at all times. They are
                  creative and highly talented and this is the secret of their
                  success in various inter house competitions like Basketball,
                  Debate, Face painting, Floral Rangoli, etc. Students of Tejas
                  House are known for their togetherness, and face adversities
                  as a team. They are the jacks of all trades performing to the
                  best of their abilities in sports and cultural events.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="p-lg-6 p-4 rounded-5 bg-light">
                <h1 className="display-5 mb-3 text-warning">Vayu</h1>
                <p className="fs-4">
                  Vayu is a Sanskrit word meaning "wind." Vayu (or Vayu Deva) is
                  also the name of the Hindu god of the wind. According to
                  ancient yogis, prana is subdivided into five vayus. Vayu House
                  also known as Yellow House is a dynamic, determined and
                  dashing race of the school. Led by a belief that they are more
                  than just “an ordinary group of students”, they truly stand
                  out. Yellow being the colour of the Sun, they are a shining
                  example to the students of the school. Every member is proud
                  indeed to be part of Vayu House that shines with the qualities
                  of perseverance, faith in one another and the ‘never give up’
                  attitude. The students of the Vayu House are usually described
                  as impeccable, par excellent, dependable, radiant, exquisite
                  and spectacular.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="p-lg-6 p-4 rounded-5 bg-light">
                <h1 className="display-5 mb-3 text-success">Prithvi</h1>
                <p className="fs-4">
                  Prithvi is a Sanskrit word which means "earth." The "Rig Veda"
                  refers to the Hindu goddess of Earth as Prithvi, who is a
                  representation of feminine energy. Prithvi is one of the five
                  elements of the universe, or the pancha mahabhuta. The colour
                  of Prithvi House is green and hence it is also called Green
                  House. Green is indicative of elements like nature, fertility
                  and life. It represents growth and harmony and is the colour
                  of the environment. The green signal always signifies
                  movement. The students of Prithvi House are down to Earth,
                  sociable and responsible. All the members are all- rounders
                  and are consistently good performers in academics as well as
                  sports
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HouseSystem;
