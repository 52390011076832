function StudentCouncil() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Student Council
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6 bg-white">
        <div className="container mt-7 text-center">
          <span className="text-uppercase text-primary fw-semibold ls-md">
            IX A
          </span>
          <div className="row">
            <div className="col-12">
              <h1 className="display-6">Captain</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container mb-lg-6">
          <div className="row g-4">
            <div className="col-lg-2 col-md-4 col-6">
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://www.acharyakulamranchi.com/public/admin/upload/council/1710928164.jpg"
                  className="rounded-circle mb-3"
                  width="150"
                  height="150"
                />
                <span className="text-uppercase text-primary fw-semibold fs-7">
                  AROHI PATHAK
                </span>
                <p className="text-uppercase fs-8">HEAD GIRL</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://www.acharyakulamranchi.com/public/admin/upload/council/1710928192.jpg"
                  className="rounded-circle mb-3"
                  width="150"
                  height="150"
                />
                <span className="text-uppercase text-primary fw-semibold fs-7">
                  AARNA JAISWAL
                </span>
                <p className="text-uppercase fs-8">DEPUTY HEAD GIRL</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StudentCouncil;
