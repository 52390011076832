function ExaminationPolicy() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Examination Policy
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container mt-3">
          <div className="row">
            <div className="col-12">
              <h1 className="display-5 mb-3">Examination Policy</h1>
              <ol>
                <li className="fs-4">
                  The students will have regular weekly tests for all the
                  subjects.
                </li>
                <li className="fs-4">
                  The class teachers will maintain the record of these tests'
                  /Assessment and inform the parents/guardians through the
                  Weekly test slip attached in this diary.
                </li>
                <li className="fs-4">
                  The assessment of these tests will be taken into consideration
                  for the promotion of students.
                </li>
                <li className="fs-4">
                  There will be one half-yearly examination, which will begin in
                  the 1st Week of September, and one annual examination that
                  will start in the last week of February/1st Week of March.
                </li>
                <li className="fs-4">
                  The syllabus for all the tests and examinations will be
                  conveyed to the students in advance.
                </li>
                <li className="fs-4">
                  If any student misses an examination or a test, he/she will
                  not be Eligible for re-examination or a re-test or
                  Supplementary test/ Examination.
                </li>
                <li className="fs-4">
                  Failure to appear in any assessment can seriously affect the
                  Student's academic progress and promotion.
                </li>
                <li className="fs-4">
                  Unfair means' use in any examination or test will disqualify
                  the student from getting promoted to the next class.
                  Disciplinary actions may be taken by the school
                  authorities/management such an event.
                </li>
                <li className="fs-4">
                  Students must have a minimum attendance of 75% during the
                  academic year to take the school examinations. No relaxation
                  will be given under any circumstances.
                </li>
                <li className="fs-4">
                  However, in case of medical reasons, the school
                  authorities/the Principal may grant a 10% relaxation to such
                  students to appear for the examination. The decision of the
                  school authorities/Principal remains final
                </li>
              </ol>
            </div>

            <div className="col-12 mt-5">
              <h1 className="display-5 mb-3">Examination Rules</h1>
              <ul>
                <li className="fs-4">
                  Students must bring their admit card on all days of
                  examination otherwise they will not be allowed to take their
                  examination.
                </li>
                <li className="fs-4">
                   They must bring all the requisite materials such as ruler,
                  pencil, eraser, sharpener etc. and not borrow the same from
                  their friends in the examination hall.
                </li>
                <li className="fs-4">
                  Students must write their name and roll no. on the question
                  paper. No rough work should be done on the question paper.
                </li>
                <li className="fs-4">
                  They must not carry any textual material, printed or written,
                  bits of paper or any other material except admit card inside
                  the examination hall.
                </li>
                <li className="fs-4">
                  Mobile phones, smart watches or other electronic devices must
                  not be found with the students during the examination.
                </li>
                <li className="fs-4">
                  Action will be taken against all those who break the above
                  rules as per school guidelines.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExaminationPolicy;
