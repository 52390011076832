function AttendanceRegularity() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Attendance & Regularity
                    <br />
                    <span className="text-primary">Introduction</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container mt-2">
          <div className="row">
            <div className="col-12">
              <h1 className="display-5 mb-3">Attendance & Regularity</h1>
              <p className="fs-4">
                The school seeks the cooperation of the parents to enforce
                punctuality and regularity. In the benefit of the child’s
                progress he / she must be regular in school. Students are
                expected to reach school on time. The school gates are closed at
                the first bell before the start of assembly. Late comers are
                allowed to attend classes only after seeking permission from the
                Principal.
              </p>
              <p className="fs-4">
                Every child is expected to have minimum 75% attendance every
                month. Pupils absent even for a day must bring a letter/ diary
                note from their parents stating the reason for absence. Prior
                permission through an application to the Principal must be taken
                for a long leave. Any medical leave must be supported by medical
                documents from the doctor. Pupils suffering from any contagious
                disease should come to school only after they have fully
                recovered supported by a fitness certificate from a doctor.
              </p>

              <h3 className="mt-5">Minimum quarantine for :</h3>
              <p className="fs-4 mb-0">Mumps & Measles 7 to 10 Days</p>
              <p className="fs-4">Chicken Pox 14 to 20 Days</p>
              <p className="fs-4">
                It is compulsory for students to attend school on the last day
                before the Summer Vacation, Puja Vacation, Diwali & Winter
                Vacations and also on the first day when the school reopens
                after the vacations.
              </p>

              <h3 className="mt-5 mb-3">
                Our Expectations from Parents and Students :
              </h3>

              <ul>
                <li>
                  Parents must co-operate with school in enforcing regularity,
                  punctuality and discipline. They should take active interest
                  in their ward's progress and activities in school, through the
                  School Diary as a means of communication between teachers and
                  parents.
                </li>
                <li>
                  In case of any change in Contact No. & address, the school
                  must be intimated in written immediately or at the earliest.
                </li>
                <li>
                  Terminal-Progress Report Card and Test ex-books should be
                  signed after each test & returned the day after receiving
                  them.
                </li>
                <li>
                  No absence will be condoned without a leave-application, nor
                  will late comers be allowed to attend class.
                </li>
                <li>
                  Care must be taken of all school property and any damage
                  caused will have to be made good by the student concerned. In
                  addition to it a fine will be imposed for the offence.
                </li>
                <li>
                  Irregular Attendance, Negligence of work assignments and any
                  form of misconduct are liable for punishment or dismissal,
                  according to the discretion of the Principal.
                </li>
                <li>Both Parents must attend the Parent-Teacher's meet.</li>
                <li>
                  Students should not carry cash or any valuable article. The
                  school will not be responsible if the same is lost.
                </li>
                <li>
                  No Objectionable Books, Periodicals, Pictures or Newspapers,
                  Cassettes or CD's, Mobile Phones, Electronic Gadgets shall be
                  brought into school without permission.
                </li>
                <li>
                  Students are expected to be in proper uniform, polished shoes,
                  Id card, neatly trimmed hair and clean nails everyday
                  including PTM & field trips.
                </li>
                <li>
                  Any student found missing in class after coming to school,
                  will be deal with severely by the school authorities.
                </li>
                <li>
                  Any sort of aggressive/ unruly behaviour and rough language
                  with the teacher/ staff/ security will not be tolerated and
                  the school will take necessary disciplinary action. Students
                  should be well behaved and above all must respect all members
                  of the staff, since conduct is the vital part of their
                  development.
                </li>
                <li>
                  Students must obey the Head Boy, Head Girl, House Captains,
                  Perfects & other students on duty.
                </li>
                <li>
                  The Principal reserves the right to expel any pupil whose
                  behavior is deemed unsatisfactory. In such cases the Principal
                  does not bind himself/herself to giving any reason.
                </li>
                <li>
                  All students should converse in English in the school premises
                  to develop their language skills.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AttendanceRegularity;
