import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function Header() {
  return (
    <>
      <section className="d-lg-block d-none">
        <div className="position-absolute mt-4 ms-5">
          <Link to="/" class="navbar-brand">
            <img
              src="/assets/images/icon.png"
              height="70"
              alt="Logo"
              className="position-relative my-logo"
            />
          </Link>
        </div>

        <div className="container border-bottom py-2">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div className="d-flex gap-2 align-items-center lh-0 d-none d-md-block">
                  <span class="btn btn-primary blink-animation py-1 rounded-pill fw-normal me-3">
                    <p className="fs-8 m-0 text-white">Admission Open</p>
                  </span>
                  <div class="btn bg-warning rounded-circle py-1 px-2 fw-normal">
                    <i className="bi bi-megaphone text-white"></i>
                  </div>
                  <span className="notice-text-animation fw-medium ms-2">
                    <TypeAnimation
                      sequence={[
                        "Get Admission Form",
                        1000,
                        "Limited Seats",
                        1000,
                      ]}
                      wrapper="span"
                      speed={60}
                      repeat={Infinity}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/8605/8605170.png"
                  width="25"
                />
              </span>
            </div>
            <div className="col-lg-5 d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex gap-2 align-items-center lh-0">
                  <a
                    href="https://api.whatsapp.com/send?phone=+91%6287842467&text=%20How%20May%20I%20Help%20You%20"
                    target="_blank"
                  >
                    <i className="bi bi-whatsapp ms-2 text-secondary"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/acharyakulamrnc"
                    target="_blank"
                  >
                    <i className="bi bi-facebook ms-2 text-secondary"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/acharyakulamrnc"
                    target="_blank"
                  >
                    <i className="bi bi-instagram ms-2 text-secondary"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@acharyakulamranchi8849"
                    target="_blank"
                  >
                    <i className="bi bi-youtube ms-2 text-secondary"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/acharyakulam-school-84a955317/"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin ms-2 text-secondary"></i>
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center ms-5">
                <a
                  href="tel:+916287842461"
                  class="btn btn-warning rounded-3 ms-1"
                >
                  <i className="bi bi-telephone-outbound"></i>
                </a>
                <div className="lh-1 ms-2 me-4">
                  <p className="es-text m-0">Need Help ? Call now</p>
                  <p className="fs-7 text-dark m-0 mt-1">6287842461</p>
                </div>
              </div>
              <div>
                <a
                  href="mailto:hrdept.acharyakulamranchi@gmail.com"
                  class="btn btn-primary rounded-3 ms-1"
                >
                  <i className="bi bi-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-lg-none d-block">
        <div className="container px-3 border-bottom py-2">
          <div className="row g-1">
            <div className="col-5">
              <span class="btn btn-primary blink-animation py-1 rounded-pill fw-normal me-3">
                <p className="fs-8 m-0 text-white">Admission Open</p>
              </span>
            </div>
            <div className="col-2 text-center">
              <span>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/8605/8605170.png"
                  width="25"
                />
              </span>
            </div>
            <div className="col-5 d-flex align-items-center justify-content-end">
              <div className="d-flex gap-2 align-items-center lh-0 sm-social-media">
                <i className="bi bi-whatsapp ms-1"></i>
                <i className="bi bi-facebook ms-1"></i>
                <i className="bi bi-instagram ms-1"></i>
                <i className="bi bi-twitter-x ms-1"></i>
                <i className="bi bi-youtube ms-1"></i>
              </div>
            </div>

            <div className="col-2">
              <Link to="/" class="navbar-brand">
                <img
                  src="/assets/images/icon.png"
                  height="55"
                  alt="Logo"
                  className=""
                />
              </Link>
            </div>
            <div className="col-10 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center ms-5">
                <a
                  href="tel:+916287842467"
                  target="_blank"
                  class="btn btn-warning rounded-3 ms-1"
                >
                  <i className="bi bi-telephone-outbound"></i>
                </a>
                <div className="lh-1 ms-2 me-4">
                  <p className="es-text m-0">Need Help ? Call now</p>
                  <p className="fs-7 text-dark m-0 mt-1">6287842467</p>
                </div>
                <div>
                  <a
                    href="#"
                    aria-controls="offcanvasExample"
                    class="btn btn-primary rounded-3 ms-1"
                  >
                    <i className="bi bi-envelope"></i>
                  </a>
                </div>

                <a
                  className="ms-3"
                  // data-bs-toggle="offcanvas"
                  // href="#offcanvasExample"
                  // role="button"
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/5259/5259008.png"
                    width="25"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav class="navbar navbar-expand-lg my-header-bg sticky-top py-2 d-lg-block d-none">
        <div class="container my-1">
          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbar-default"
          >
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  About Us
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/introduction" class="dropdown-item">
                      Introduction
                    </Link>
                  </li>
                  <li>
                    <Link to="/mission-vision" class="dropdown-item">
                      Mission & Vision
                    </Link>
                  </li>
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Torch Bearers
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <Link to="/swami-ramdev-ji" class="dropdown-item">
                          Yogrishi Swami Ramdev Ji
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/acharya-balkrishna-ji"
                          class="dropdown-item white-space lh-sm"
                        >
                          Shraddhey Acharya Balkrishna Ji
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/chairman" class="dropdown-item">
                      Chairman's Message
                    </Link>
                  </li>
                  <li>
                    <Link to="/principal" class="dropdown-item">
                      Principal's Desk
                    </Link>
                  </li>
                  <li>
                    <hr class="mx-3" />
                  </li>
                  <li>
                    <Link to="/mandatory-disclosure" class="dropdown-item">
                      Mandatory Disclosure
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Admission
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/admission-procedure" class="dropdown-item">
                      Admission Procedure
                    </Link>
                  </li>
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Fee Structure
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          href="/assets/fee/NUR-UKG-1.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          Nursery, LKG, UKG
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/fee/I & II.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          I & II
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/fee/III.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          III
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/fee/IV TO VIII.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          IV to VIII
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Application Forms
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="/media/prospectus.pdf"
                      target="_blank"
                    >
                      Admission Pamphlet 2024-25
                    </a>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Rules & Regulation
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Documents Required for Admission
                    </Link>
                  </li>
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Syllabus
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          href="/assets/syllabus/PRATYUS.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          PRATYUS (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/ARUN.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          ARUN (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/UDAY.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          UDAY (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/I.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          I (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/II.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          II (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/III.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          III (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/IV.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          IV (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/V.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          V (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/VI.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          VI (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/VII.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          VII (2025 - 26)
                        </a>
                      </li>
                      <li>
                        <a
                          href="/assets/syllabus/VIII.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          VIII (2025 - 26)
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href="/media/prospectus.pdf"
                      target="_blank"
                      class="dropdown-item"
                    >
                      Prospectus
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Academics
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li class="dropdown-submenu dropend">
                    <a
                      class="dropdown-item dropdown-list-group-item dropdown-toggle"
                      href="#"
                    >
                      Calendar
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          href="assets/docs/school-calender.pdf"
                          target="_blank"
                          class="dropdown-item"
                        >
                          School Calendar
                        </a>
                      </li>
                      <li>
                        <Link to="/" class="dropdown-item">
                          Activity Calendar
                        </Link>
                      </li>
                      <li>
                        <Link to="/" class="dropdown-item">
                          Weekly Test Schedule
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Academic Syllabus
                    </Link>
                  </li>
                  <li>
                    <Link to="/course-curriculum" class="dropdown-item">
                      Courses & Curriculum
                    </Link>
                  </li>
                  <li>
                    <Link to="/examination-policy" class="dropdown-item">
                      Examination Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/attendance-regularity" class="dropdown-item">
                      Attendance & Regularity
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Topper Students
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Download TC
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/assets/docs/attendance.pdf"
                      target="_blank"
                      class="dropdown-item"
                    >
                      100% Attendance
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Students's Life
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/" class="dropdown-item">
                      Student Concil
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      School Uniform
                    </Link>
                  </li>
                  <li>
                    <Link to="/house-system" class="dropdown-item">
                      House System
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Today's Birthday
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Yoga & Sports Activity
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Gallery & Achivement
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      Photos
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Vidoes
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Media Print
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Blogs and Articles
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown dropdown-fullwidth">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Facilities
                </a>
                <div class="dropdown-menu dropdown-menu-md">
                  <div class="px-4 pt-2 pb-2">
                    <div class="row">
                      <div class="col-lg-4 col-12 text-center">
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName:
                                    "Natural Environment with Lush Green Campus",
                                }}
                                class="nav-link"
                              >
                                Natural Environment with Lush Green Campus
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Administrative Block",
                                }}
                                class="nav-link"
                              >
                                Administrative Block
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Assembly Area",
                                }}
                                class="nav-link"
                              >
                                Assembly Area
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Game Facilities",
                                }}
                                class="nav-link"
                              >
                                Game Facilities
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Spacius Playground",
                                }}
                                class="nav-link"
                              >
                                Spacious Playground
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Art & Craft Arelier",
                                }}
                                class="nav-link"
                              >
                                Art & Craft Arelier
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Yog",
                                }}
                                class="nav-link"
                              >
                                Yog
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Yagya",
                                }}
                                class="nav-link"
                              >
                                Yagya
                              </Link>
                            </li>
                          </ul>
                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              Fee Payment
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Vedic Center",
                                }}
                                class="nav-link"
                              >
                                Vedic Center
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Library",
                                }}
                                class="nav-link"
                              >
                                Library
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Dance Studio",
                                }}
                                class="nav-link"
                              >
                                Dance Studio
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Music Lab",
                                }}
                                class="nav-link"
                              >
                                Music Lab
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Health & Care Unit",
                                }}
                                class="nav-link"
                              >
                                Health & Care Unit
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Transport Facility",
                                }}
                                class="nav-link"
                              >
                                Transport Facility
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Computer Lab",
                                }}
                                class="nav-link"
                              >
                                Computer Lab
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Human Resource Lab",
                                }}
                                class="nav-link"
                              >
                                Human Resource Lab
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Utra Modern Science Lab",
                                }}
                                class="nav-link"
                              >
                                Utra Modern Science Lab
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Kids Activity Zone",
                                }}
                                class="nav-link"
                              >
                                Kids Activity Zone
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 mt-4 mt-lg-0 text-center">
                        <div>
                          <ul class="nav flex-column">
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Horse Riding",
                                }}
                                class="nav-link"
                              >
                                Horse Riding
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName:
                                    "Motivational & Spritual Session",
                                }}
                                class="nav-link"
                              >
                                Motivational & Spritual Session
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Skill Development",
                                }}
                                class="nav-link"
                              >
                                Skill Development
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName:
                                    "Carpentring, Gradining & Farming",
                                }}
                                class="nav-link"
                              >
                                Carpentring, Gardening & Farming
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Foreign Language",
                                }}
                                class="nav-link"
                              >
                                Foreign Language
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName:
                                    "Campus fully covered under CCTV Survillance",
                                }}
                                class="nav-link"
                              >
                                Campus fully covered under CCTV Survillance
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName:
                                    "Smart-Board Equipped Classrooms",
                                }}
                                class="nav-link"
                              >
                                Smart-Board Equipped Classrooms
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/facility"
                                state={{
                                  facilityName: "Modern Infra Structure",
                                }}
                                class="nav-link"
                              >
                                Modern Infra Structure
                              </Link>
                            </li>
                          </ul>
                          <div class="mt-4">
                            <a
                              href="#"
                              class="btn btn-outline-primary btn-sm px-5"
                            >
                              View all
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown">
                <span
                  class="nav-link dropdown-toggle cursor-pointer"
                  id="navbarPages"
                >
                  Faculty
                </span>
                <ul
                  class="dropdown-menu dropdown-menu-arrow"
                  aria-labelledby="navbarPages"
                >
                  <li>
                    <Link to="/" class="dropdown-item">
                      Teaching Staff
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Office Staff
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Facility Department
                    </Link>
                  </li>
                  <li>
                    <Link to="/" class="dropdown-item">
                      Supporting Staff
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span class="btn btn-primary py-1 rounded-pill fw-normal me-3">
                    <p className="fs-8 m-0 text-white">Login</p>
                  </span>
                </a>
              </li>
            </ul>
            <form class="d-flex align-items-center">
              <span class="position-absolute ps-3 search-icon">
                <i class="bi bi-search"></i>
              </span>
              <label for="search" class="visually-hidden"></label>
              <input
                type="search"
                id="search"
                class="form-control ps-6 rounded-3"
                placeholder="Search"
              />
            </form>
          </div>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-start bg-white"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body pt-2">
          <div
            className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
            id="accordionFlushExample"
          >
            <div className="accordion-item mb-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button p-2 rounded-3 px-4 bg-light"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-menuOne"
                  aria-expanded="true"
                  aria-controls="flush-menuOne"
                >
                  <p className="fs-5 text-dark fw-medium m-0">About Us</p>
                </button>
              </h2>
              <div
                id="flush-menuOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body px-0">
                  <p className="fs-5 mb-0 fw-light text-secondary">
                    <ul className="mb-0">
                      <li>Introduction</li>
                      <li>Mission & Vision</li>
                      <li>Torch Bearers</li>
                      <li>Chairman's Message</li>
                      <li>Principal's Desk</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button p-2 rounded-3 px-4 bg-light collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-menuTwo"
                  aria-expanded="false"
                  aria-controls="flush-menuTwo"
                >
                  <p className="fs-5 text-dark fw-medium m-0">Admission</p>
                </button>
              </h2>
              <div
                id="flush-menuTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
                style={{}}
              >
                <div className="accordion-body px-0">
                  <p className="fs-5 fw-light mb-0 text-secondary">
                    <ul className="mb-0">
                      <li>Admission Procedure</li>
                      <li>Fee Structure</li>
                      <li>Application Forms</li>
                      <li>Admission Pamphlet 2024-25</li>
                      <li>Rules & Regulation</li>
                      <li>Documents Required for Admission</li>
                      <li>Syllabus</li>
                      <li>Prospectus</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button p-2 rounded-3 px-4 bg-light collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-menuThree"
                  aria-expanded="false"
                  aria-controls="flush-menuThree"
                >
                  <p className="fs-5 text-dark fw-medium m-0">Academics</p>
                </button>
              </h2>
              <div
                id="flush-menuThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
                style={{}}
              >
                <div className="accordion-body px-0">
                  <p className="fs-5 fw-light mb-0 text-secondary">
                    <ul className="mb-0">
                      <li>Calendar</li>
                      <li>Academic Syllabus</li>
                      <li>Courses & Curriculum</li>
                      <li>Examination Policy</li>
                      <li>Attendance & Regularity</li>
                      <li>Topper Students</li>
                      <li>Download TC</li>
                      <li>100% Attendance</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button p-2 rounded-3 px-4 bg-light collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-menuFour"
                  aria-expanded="false"
                  aria-controls="flush-menuFour"
                >
                  <p className="fs-5 text-dark fw-medium m-0">
                    Students's Life
                  </p>
                </button>
              </h2>
              <div
                id="flush-menuFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
                style={{}}
              >
                <div className="accordion-body px-0">
                  <p className="fs-5 fw-light mb-0 text-secondary">
                    <ul className="mb-0">
                      <li>Student Concil</li>
                      <li>School Uniform</li>
                      <li>House System</li>
                      <li>Today's Birthday</li>
                      <li>Yoga & Sports Activity</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button p-2 rounded-3 px-4 bg-light collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-menuFive"
                  aria-expanded="false"
                  aria-controls="flush-menuFive"
                >
                  <p className="fs-5 text-dark fw-medium m-0">
                    Gallery & Achivement
                  </p>
                </button>
              </h2>
              <div
                id="flush-menuFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
                style={{}}
              >
                <div className="accordion-body px-0">
                  <p className="fs-5 fw-light mb-0 text-secondary">
                    <ul className="mb-0">
                      <li>Photos</li>
                      <li>Vidoes</li>
                      <li>Media Print</li>
                      <li>Blogs and Articles</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
