function Career() {
  return (
    <>
      <section className="other-pages-hero-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">ACHARYAKULAM</span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Career
                    <br />
                    <span className="text-primary">Join Our Team</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container my-5">
          <span className="text-uppercase text-primary fw-semibold ls-md">
            career at Acharyakulam
          </span>
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 col-12">
              <h1 className="display-4 mt-2 mb-3 fw-bold">
                All open positions
              </h1>
              <p className="fs-3 pe-lg-8 me-lg-8">
                Explore rewarding career opportunities at Acharyakulam, where
                dedication and innovation drive excellence in education and
                personal growth.
              </p>
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-12">
              <div className="mb-5">
                <h2 className="fw-bold fs-3 text-uppercase">Teaching Staff</h2>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-lg fs-4 text-nowrap">
                  <tbody>
                    <tr>
                      <td>PGT</td>
                      <td>
                        English, Political Science, Computer Science and Dance
                      </td>
                    </tr>
                    <tr>
                      <td>PRT</td>
                      <td>Instrumental Music - Guitar/ Sitar/ Keyboard,Band</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-8">
            <div className="col-12">
              <div className="mb-5">
                <h2 className="fw-bold fs-3 text-uppercase">Non-Teaching Staff</h2>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-lg fs-4 text-nowrap">
                  <tbody>
                    <tr>
                      <td>Librarian</td>
                      <td>B. Lib.</td>
                    </tr>
                    <tr>
                      <td>Warden</td>
                      <td>Graduate in any subject</td>
                    </tr>
                    <tr>
                      <td>Counsellor</td>
                      <td>M.A. Psychology/Counselling Psychology</td>
                    </tr>
                    <tr>
                      <td>School Nurse</td>
                      <td>
                        Bachelor of Science in Nursing degree or higher degree
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 bg-white">
        <div className="container mb-5">
          <span className="text-uppercase text-primary fw-semibold ls-md">
            How to Apply
          </span>
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 col-12">
              <h1 className="display-4 mt-2 mb-3 fw-bold">
                Application Process
              </h1>
              <p className="fs-3 pe-lg-8 me-lg-8">
                Send resume at{" "}
                <a href="mailto:info@acharyakulamranchi.com">
                  info@acharyakulamranchi.com
                </a>
              </p>
              <p className="fs-3 pe-lg-8 me-lg-8 text-dark">For any Query</p>
              <p className="fs-3 pe-lg-8 me-lg-8">
                Contact@: +91 6287842467 | +91 6287842461
                <br />
                Acharyakulam, Namkum, Near Police Station,
                <br />
                Ranchi, India, Jharkhand
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Career;
